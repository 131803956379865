import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";

function Animate({ children, delay, duration }) {
  const Ref = useRef(null);
  const isInView = useInView(Ref);
  const mainC = useAnimation();

  useEffect(() => {
    if (isInView) mainC.start("visible");
  }, [isInView]);
  return (
    <div ref={Ref} style={{ position: "relative" }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 40 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainC}
        transition={{ duration: duration, delay: delay }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Animate;
