import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = () => {
    let newLang = i18n.language === "en" ?  "ar" : "en";
    i18n.changeLanguage(newLang);
  };

  return (
    <button className="btn" onClick={handleLanguageChange}>
      { i18n.language === "ar" ?  <span className="eng-lang text-center">English</span> : <span className="arabic-lang text-center" style={{ fontSize: '1.7em', fontFamily: 'Amiri' }} >عربي</span> }
    </button>
  );
};

export default LanguageSwitcher;
