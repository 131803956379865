import odoo_gold from "../assets/img/Odoo-gold-partner.webp";
import alkhan from "../assets/img/alkhan.jpg";
import masdar from "../assets/img/Masdar-Logo.png";
import nakheel from "../assets/img/nakeel.png";
import das_holding from "../assets/img/DAS-Holding-Logo.png";
import flydubai from "../assets/img/fly-dubai.png";
import oca from "../assets/img/odoo_oca-1.png";
import maaalthahab from "../assets/img/maaalthahab.jpg";
import almaskan from "../assets/img/almaskan.jpg";

const imgs = [odoo_gold, alkhan, masdar, nakheel, das_holding, oca, maaalthahab, flydubai, almaskan ];

function Slider() {
  return (
    <div class="container">
      <div class="customer-logos slider">
          <div class="slide marquee-content"> 
            {imgs.map((i, index) => (
              <div className="SlideshowrSlider marquee-item" key={index}>
                <img src={i} alt="" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Slider;
