import "./App.css";
import home_img from "./assets/img/odoo.webp";
import open_source_img from "./assets/img/open_source.jpg";
import customizable_img from "./assets/img/customizable.jpg";
import modular_img from "./assets/img/modular.jpg";
import support_img from "./assets/img/pexels-andrea-piacquadio-3756679.jpg";
import i18n from "i18next";
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";
import Animate from "./components/Animate";
import Slider from "./components/Slider";
import LanguageSwitcher from "./components/LanguageSwitcher";
import { initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
  faLocationDot,
  faPhone,
  faEnvelope,
  faPeopleCarry,
  faStar,
  faTools,
  faCode ,
  faTasks,
  faUserCog,
  faHandHoldingHeart,
  faChalkboardTeacher,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

import team0 from "./assets/img/pexels-antoni-shkraba-7163360.jpg";
import team1 from "./assets/img/pexels-mizuno-k-12898977.jpg";
import team2 from "./assets/img/pexels-antoni-shkraba-5467601.jpg";
import team3 from "./assets/img/pexels-cedric-fauntleroy-8154931.jpg";
import team4 from "./assets/img/pexels-rdne-stock-project-7580994.jpg";
import team5 from "./assets/img/pexels-blue-bird-7242772.jpg";

const resources = {
  en: { translation: translationEN, },
  ar: { translation: translationAR, },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false, },
});

function App() {
  const [navtogstate, setnavtogstate] = useState(0);
  const [pricing, setpricing] = useState('monthly');
  const { t } = useTranslation();
  const submitContactUs = (e) => {
    e.preventDefault();
    const name    = document.querySelector('input[name=name]').value;
    const email   = document.querySelector('input[name=email]').value;
    const mobile  = document.querySelector('input[name=mobile]').value;
    const message = document.querySelector('textarea[name=message]').value;
    const xhttp = new XMLHttpRequest();
    let not_submited = document.querySelector('#not_submited');
    if(not_submited.classList.contains("d-none")){
      not_submited.classList.add("d-none");
    }
    let submited = document.querySelector('#submited');
    if(submited.classList.contains("d-none")){
      submited.classList.add("d-none");
    }
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        try{
          let jres = JSON.parse(this.responseText);
          if(jres.status === "OK"){
            const form = document.querySelector('form');
            form.reset();
            let submited = document.querySelector('#submited');
            submited.classList.remove("d-none");
          }else{
            let not_submited = document.querySelector('#not_submited');
            not_submited.classList.remove("d-none");
          }
        } catch (err){
          let not_submited = document.querySelector('#not_submited');
          not_submited.classList.remove("d-none");
        }
      }
    };
    xhttp.open("POST", "/contactUs", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send(`name=${name}&email=${email}&mobile=${mobile}&message=${message}`);
  };

  const Navul = () => {
    return (
      <div className={`collapse navbar-collapse ${navtogstate ? "show" : ""}`} style={{ left: i18n.language === 'en'? 0 : '' }} >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <Animate delay={0} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link " href="#home" onClick={() => setnavtogstate(0)} >
                {t("home")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.1} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link" href="#services" onClick={() => setnavtogstate(0)} >
                {t("services")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.1} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link" href="#features" onClick={() => setnavtogstate(0)} >
                {t("features")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.2} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link" href="#pricing" onClick={() => setnavtogstate(0)} >
                {t("pricing")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.3} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link" href="#Partners" onClick={() => setnavtogstate(0)} >
                {t("partners")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.3} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link" href="#team" onClick={() => setnavtogstate(0)} >
                {t("team")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.4} duration={0.3}>
            <li className="nav-item">
              <a className="nav-link" href="#ContactUs" onClick={() => setnavtogstate(0)} >
                {t("contact_us")}
              </a>
            </li>
          </Animate>
          <Animate delay={0.4} duration={0.3}>
            <li className="nav-item text-center">
              <LanguageSwitcher/>
            </li>
          </Animate>
        </ul>
      </div>
    );
  };

  return (
    <div className="App" dir={ i18n.language === 'ar' ? 'rtl' : 'ltr' } style={{ fontFamily: i18n.language === 'ar' ? 'Amiri' : '' , fontSize: '1.3em' }} >
      <nav className="navbar navbar-expand-lg navbar-light navbar-stick-dark" data-navbar="smart" >
        <div className=" container">
          <div className="navbar-left mr-4">
            <div className="navbar-toggler" onClick={() => setnavtogstate(1)}>
              <span className="navbar-toggler-icon"></span>
            </div>
            <a className="navbar-brand logo-container" href="#home">
              <span className="font-weight-bold">
                <span className="logo-s">H</span>
                <span className="logo-b">T</span>
                <span className="logo-s" style={{ fontStyle: 'italic' }} >E</span>
              </span>
            </a>
          </div>
          {navtogstate ? (
            <div className={`collapse navbar-collapse-container ${ navtogstate ? "show" : "" }`} onClick={() => setnavtogstate(0)} >
              <Navul />
            </div>
          ):(
            <Navul />
          )}
        </div>
      </nav>
      <header id="home" className="header text-white h-fullscreen text-center text-lg-left" >
        <div className="container">
          <div dir="ltr" className="row align-items-center h-100">
            <div className="col-lg-6">
              <Animate delay={0.1} duration={0.5}>
                <h2 className="home-text text-nowrap">{t("level_up")}</h2>
              </Animate>
              <Animate delay={0.2} duration={0.5}>
                <p className="lead mt-5 home-text">{t("level_up_text")} <a className="text-success" href="#pricing">{t("get_it")}</a></p>
              </Animate>
            </div>
            <div className="col-lg-5 ml-auto">
              <img className="mt-5 home-img" src={home_img} alt="Home" />
            </div>
          </div>
        </div>
      </header>
      <main className="main-content">
        <section id="services" class="section-big main-color">
          <div className="bg-darker-gray" style={{ padding: '8%',fontFamily: i18n.language === 'ar' ? 'Amiri' : '' , fontSize: i18n.language === 'en' ? '1.1rem' : '' }}>
            <div class="row">
              <div class="col-md-12 pb-20 text-center"> 
                <h2 class="section-title mb-6"><span> {t("services_title_1")} <strong class="primary-color">{t("services_title_2")}</strong> {t("services_title_3")} </span></h2>
                <p class="section-sub-title"> {t("services_subtitle")} <br/> </p>
                <div class="exp-separator center-separator">
                  <div class="exp-separator-inner">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <ul class="i-list medium">
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faUser} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("consulting")}</h3>
                      <p class="sub-title">{t("consulting_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                    <div style={ i18n.language === 'en'? { left: '34px'} : { right: '34px' }} class="iconlist-timeline"></div>
                  </li>
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faTasks} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("project_management")}</h3>
                      <p class="sub-title">{t("project_management_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                    <div style={ i18n.language === 'en'? { left: '34px'} : { right: '34px' }} class="iconlist-timeline"></div>
                  </li>
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faPeopleCarry} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("migration")}</h3>
                      <p class="sub-title">{t("migration_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="i-list medium">
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faCode} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("development")}</h3>
                      <p class="sub-title">{t("development_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                    <div style={ i18n.language === 'en'? { left: '34px'} : { right: '34px' }} class="iconlist-timeline"></div>
                  </li>
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faUserCog} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("customization")}</h3>
                      <p class="sub-title">{t("customization_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                    <div style={ i18n.language === 'en'? { left: '34px'} : { right: '34px' }} class="iconlist-timeline"></div>
                  </li>
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faTools} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("maintenance")}</h3>
                      <p class="sub-title">{t("maintenance_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <ul class="i-list medium">
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faChalkboardTeacher} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("training")}</h3>
                      <p class="sub-title">{t("training_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                    <div style={ i18n.language === 'en'? { left: '34px'} : { right: '34px' }} class="iconlist-timeline"></div>
                  </li>
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faStar} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("hire_expert")}</h3>
                      <p class="sub-title">{t("hire_expert_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                    <div style={ i18n.language === 'en'? { left: '34px'} : { right: '34px' }} class="iconlist-timeline"></div>
                  </li>
                  <li class="i-list-item">
                    <div style={ i18n.language === 'ar'? { marginLeft: '25px'} : { marginRight: '25px' }} class="icon"> <FontAwesomeIcon icon={faHandHoldingHeart} /> </div>
                    <div class="icon-content">
                      <h3 class="title">{t("support")}</h3>
                      <p class="sub-title">{t("support_text")}
                        <a className="text-success" href="#ContactUs" >{t("order_it")}</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <hr/>
        <section id="features" className="section bg-darker-gray">
          <div className="container">
            <div className="row gap-y align-items-center sm text-center">
              <div className="col-md-6 ml-auto">
                <Animate delay={0.3} duration={0.5}>
                  <h4>{t("open_source")}</h4>
                  <p>{t("open_source_text")}</p>
                </Animate>
              </div>
              <div className="col-md-5 order-md-first">
                <img className="rounded shadow-2" src={open_source_img} alt="Open Source Software" />
              </div>
            </div>
            <hr className="my-8" />
            <div className="row gap-y align-items-center text-center">
              <div className="col-md-6 mr-auto">
                <Animate delay={0.2} duration={0.5}>
                  <h4>{t("customizable")}</h4>
                  <p>{t("customizable_text")}</p>
                </Animate>
              </div>
              <div className="col-md-5">
                <img className="rounded shadow-2" src={customizable_img} alt="..." />
              </div>
            </div>
            <hr className="my-8" />
            <div className="row gap-y align-items-center text-center">
              <div className="col-md-6 ml-auto">
                <Animate delay={0.2} duration={0.5}>
                  <h4>{t("modular")}</h4>
                  <p>{t("modular_text")}</p>
                </Animate>
              </div>
              <div className="col-md-5 order-md-first">
                <img className="rounded shadow-2" src={modular_img} alt="..." />
              </div>
            </div>
            <hr className="my-8" />
            <div className="row gap-y align-items-center text-center">
              <div className="col-md-6 mr-auto">
                <Animate delay={0.2} duration={0.5}>
                  <h4>{t("easy")}</h4>
                  <p>{t("easy_text")}</p>
                </Animate>
              </div>
              <div className="col-md-5">
                <img className="rounded shadow-2" src={support_img} alt="It Support" />
              </div>
            </div>
          </div>
        </section>
        <hr/>
        <section id="pricing" className="section bg-darker-gray">
          <div className="container">
            <header className="section-header">
              <h2>{t("pricing_plans")}</h2>
              <hr/>
              <p className="lead"></p>
            </header>

            <div className="text-center my-7">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label className={`btn btn-round btn-outline-secondary w-150 ${ pricing === 'monthly' ? "active" : "" }`} >
                  <input type="radio" name="pricing" value="monthly" onClick={() => setpricing('monthly')} />{t("monthly")}</label>
                <label className={`btn btn-round btn-outline-secondary w-150 ${ pricing === 'yearly' ? "active" : "" }`} >
                  <input type="radio" name="pricing" value="yearly" onClick={() => setpricing('yearly')} />{t("yearly")}</label>
              </div>
            </div>

              <div className="row gap-y text-center">
                <div className="col-md-4">
                  <div className="pricing-1">
                    <p className="plan-name text-info">{t("starter")}</p>
                    <br />
                    <h2 className="price text-info">
                      <span> 
                        { pricing === 'monthly' ? 99 : 1099 } 
                        <span className="price-unit" > 
                          {t("currency_code")}{ pricing === 'monthly' ? t("per_month") : t("per_year") } 
                        </span>
                      </span>
                    </h2>
                    <p className="small">&nbsp;</p>
                    { pricing === 'monthly' ? (
                    <div className="text-muted">
                      <small>3 {t("users")}</small> <br/>
                      <small>10 {t("modules")}</small> <br/>
                      <small>1 {t("branch")}</small> <br/>
                      <small>10 {t("gb_storage")}</small> <br/>
                      <small>1 {t("domain")}</small> <br/>
                    </div> 
                    ) : (
                    <div className="text-muted">
                      <small>9 {t("users")}</small> <br/>
                      <small>15 {t("modules")}</small> <br/>
                      <small>1 {t("branch")}</small> <br/>
                      <small>20 {t("gb_storage")}</small> <br/>
                      <small>1 {t("domain")}</small> <br/>
                    </div>
                    )}
                    <br/>
                    <a href="#ContactUs" className="btn btn-info" >{t("order_it")}</a>
                    <br/>
                    <br/>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pricing-1">
                    <p className="plan-name text-success">{t("business")}</p>
                    <br />
                    <h2 className="price text-success">
                      <span> 
                        { pricing === 'monthly' ? 356 : 4200 }
                        <span className="price-unit" > 
                          {t("currency_code")}{ pricing === 'monthly' ? t("per_month") : t("per_year") } 
                        </span>
                      </span>
                    </h2>
                    <p className="small">&nbsp;</p>
                    { pricing === 'monthly' ? 
                    <div className="text-muted">
                      <small>100 {t("users")}</small><br/>
                      <small>100 {t("modules")}</small><br/>
                      <small>5 {t("branches")}</small><br/>
                      <small>100 {t("gb_storage")}</small><br/>
                      <small>5 {t("domains")}</small><br/>
                    </div>
                    :
                    <div className="text-muted">
                      <small><span class="text-success">{t("unlimited")}</span> {t("for_users")}</small> <br/>
                      <small><span class="text-success">{t("unlimited")}</span> {t("for_modules")}</small> <br/>
                      <small><span class="text-success">{t("unlimited")}</span> {t("for_branches")}</small> <br/>
                      <small><span class="text-success">+500</span>{t("gb_storage")}</small> <br/>
                      <small><span class="text-success">{t("unlimited")}</span> {t("for_domains")}</small> <br/>
                    </div>
                    }
                    <br/>
                    <a href="#ContactUs" className="btn btn-success" >{t("order_it")}</a>
                    <br/>
                    <br/>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pricing-1 popular">
                    <p className="plan-name text-info">{t("professional")}</p>
                    <br />
                    <h2 className="price text-info">
                      <span> 
                        { pricing === 'monthly' ? 150 : 1700 }
                        <span className="price-unit" > 
                          {t("currency_code")}{ pricing === 'monthly' ? t("per_month") : t("per_year") } 
                        </span>
                      </span>
                    </h2>
                    <p className="small">&nbsp;</p>
                    { pricing === 'monthly' ? 
                    <div className="text-muted">
                      <small>10 {t("users")}</small><br/>
                      <small>25 {t("modules")}</small> <br/>
                      <small>1 {t("branch")}</small><br/>
                      <small>50 {t("gb_storage")}</small><br/>
                      <small>1 {t("domain")}</small><br/>
                    </div>
                    :
                    <div className="text-muted">
                      <small>20 {t("users")}</small><br/>
                      <small>50 {t("modules")}</small> <br/>
                      <small>3 {t("branches")}</small><br/>
                      <small>100 {t("gb_storage")}</small><br/>
                      <small>3 {t("domains")}</small><br/>
                    </div>
                    }
                    <br/>
                    <a href="#ContactUs" className="btn btn-info" >{t("order_it")}</a>
                    <br/>
                    <br/>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <section id="Partners" className="section text-center">
          <div className="container">
            <div className="section">
              <Animate delay={0.2} duration={0.5}>
                <h2 className="lead-8"> <strong>{t("our_partners")}</strong> </h2>
                <br/> <br/> <br/>
              </Animate>
              <Slider/>
            </div>
          </div>
        </section>
        <section id="team" class="section team bg-darker-gray">
          <header className="section-header">
            <h2 className="lead-8">
              <Animate delay={0.2} duration={0.5}> <strong>{t("team")}</strong> </Animate>
            </h2>
            <hr/>
          </header>
          <div class="container">
            <div class="row gy-5">
              <div class="col-lg-4 col-md-6 member aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div class="member-img">
                  <img src={team0} class="img-fluid" alt="" />
                  <div class="social">
                    <a href="#"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                  </div>
                </div>
                <div class="member-info text-center">
                  <h4>{t("team_member_0")}</h4>
                  <span>Project Manager</span>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 member aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                <div class="member-img">
                  <img src={team1} class="img-fluid" alt="" />
                  <div class="social">
                    <a href="#"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                  </div>
                </div>
                <div class="member-info text-center">
                  <h4>{t("team_member_1")}</h4>
                  <span>CRM</span>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 member aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                <div class="member-img">
                  <img src={team2} class="img-fluid" alt="" />
                  <div class="social">
                    <a href="#"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                  </div>
                </div>
                <div class="member-info text-center">
                  <h4>{t("team_member_2")}</h4>
                  <span>IT Support</span>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 member aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
                <div class="member-img">
                  <img src={team3} class="img-fluid" alt="" />
                  <div class="social">
                    <a href="#"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                  </div>
                </div>
                <div class="member-info text-center">
                  <h4>{t("team_member_3")}</h4>
                  <span>Accountant</span>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 member aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                <div class="member-img">
                  <img src={team4} class="img-fluid" alt="" />
                  <div class="social">
                    <a href="#"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                  </div>
                </div>
                <div class="member-info text-center">
                  <h4>{t("team_member_4")}</h4>
                  <span>Develper</span>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 member aos-init aos-animate" data-aos="fade-up" data-aos-delay="600">
                <div class="member-img">
                  <img src={team5} class="img-fluid" alt="" />
                  <div class="social">
                    <a href="#"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                    <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
                  </div>
                </div>
                <div class="member-info text-center">
                  <h4>{t("team_member_5")}</h4>
                  <span>Designer</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr/>
        <section id="ContactUs" className="section bg-darker-gray text-center">
          <div className="container">
            <header className="section-header">
              <h2 className="lead-8">
                <Animate delay={0.2} duration={0.5}> <strong>{t("contact_us")}</strong> </Animate>
              </h2>
                <p className="lead mt-5">{t("contact_us_text")}</p>
              <hr/>
            </header>

            <div className="row">
              <form method="POST" className="lg-col-9 md-col-9 col mx-auto input-border" onSubmit={submitContactUs} >
                <div className="form-group">
                  <input type="text" className="form-control form-control-lg" name="name" placeholder={t("name")} required="1" />
                </div>
                <div className="form-group">
                  <input type="text" className="form-control form-control-lg" name="email" placeholder={t("email")} />
                </div>
                <div className="form-group"> 
                  <input type="mobile" className="form-control form-control-lg" name="mobile" placeholder={t("mobile")} />
                </div>
                <div className="form-group">
                  <textarea type="text" className="form-control form-control-lg" name="message" placeholder={t("message")} required="1" />
                </div>
                <div id="submited" className="text-success mb-2 d-none">{t("submited")}</div>
                <div id="not_submited" className="text-danger mb-2 d-none">{t("not_submited")}</div>
                <button id="contact_us_btn" className="btn btn-block btn-xl btn-success"> {t("submit")} </button>
              </form>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <div className="row gap-y align-items-center justify-content-between">
            <div className="col-md-3 text-center text-md-left">
              <a href="#home" className="text-dark font-weight-bold text-nowrap"> {t("company_name")} </a>
            </div>
            <div className="col-md-3" >
              <a className="text-nowrap" style={{ color: 'black' }} target="new" href="https://www.google.com/maps/place/Oxford+Tower/@25.1791721,55.2762746,16z/data=!3m1!5s0x3e5f6834c8725cd1:0xe17e9b101b6648b8!4m6!3m5!1s0x3e5f683509d47ed1:0xe12f8b0fa343d29c!8m2!3d25.1771743!4d55.2732759!16s%2Fg%2F11clwnp2z1?entry=ttu" >
                <FontAwesomeIcon style={{ color: 'black', marginLeft: '5px', marginRight: '5px' }} icon={faLocationDot} />
                Oxford Tower Dubai
              </a><br/>
              <a className="text-nowrap" style={{ color: 'black' }} href="mailto:info@hasabtech.com" >
                <FontAwesomeIcon style={{ color: 'black', marginLeft: '5px', marginRight: '5px' }} icon={faEnvelope} />
                hasabtechnology@gmail.com
              </a><br/>
              <a className="text-nowrap" style={{ color: 'black' }} href="tel://+971566486002" >
                <FontAwesomeIcon style={{ color: 'black', marginLeft: '5px', marginRight: '5px' }} icon={faPhone} />
                +971566486002
              </a>
            </div>
            <div className="col-md-3 text-center text-md-right flaot-right text-dark" >
              <small>© {t("copy_right")} 2024</small>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
